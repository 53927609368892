
import { Component, Vue } from "vue-property-decorator";
import { Downloader, Reports } from "@/hooks";
import { IncidentReport } from "models";
import JsonExcel from "@/components/JsonExcel.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import dayjs from "dayjs";

@Component({
  components: {
    JsonExcel,
  },
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class Incident_Report extends Vue {
  reports: IncidentReport[] = [];
  reportsFiltered: IncidentReport[] = [];
  loading = false;
  search = "";
  sortDesc = true;
  sortBy = "date";
  expanded: IncidentReport[] = [];
  headers = [
    { text: "Incident Number", value: "incidentNumber" },
    { text: "Incident Type", value: "incidentType.name" },
    { text: "Date", value: "date" },
    { text: "Time", value: "time" },
    { text: "ARO/SO", value: "armResponseOfficer.name" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    "Incident Number": string;
    "Incident Type": string;
    Date: string;
    Time: string;
    "ARO/SO": string;
    Firearm: string;
    "Investigation Officer": string;
    "Officer Contact": string;
  }[] = [];
  expFields = {
    "Incident Number": "Incident Number",
    "Incident Type": "Incident Type",
    Date: "Date",
    Time: "Time",
    "ARO/SO": "ARO/SO",
    Firearm: "Firearm",
    "Investigation Officer": "Investigation Officer",
    "Officer Contact": "Officer Contact",
  };

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const res = await Reports.getIncidentReport();
      this.reports = res;
      this.expReports = this.populateExportData(this.reports);
      this.loading = false;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  updateReportsFiltered(event: any) {
    this.reportsFiltered = event ? (event as any[]) : this.reports;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.reportsFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      const columns = [
        { header: "Incident Number", dataKey: "Incident Number" },
        { header: "Incident Type", dataKey: "Incident Type" },
        { header: "Date", dataKey: "Date" },
        { header: "Time", dataKey: "Time" },
        { header: "ARO/SO", dataKey: "ARO/SO" },
        { header: "Firearm", dataKey: "Firearm" },
        { header: "Investigation Officer", dataKey: "Investigation Officer" },
        { header: "Officer Contact", dataKey: "Officer Contact" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `Exception Report (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.getReportsFiltered,
        horizontalPageBreakRepeat: "fullName",
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  populateExportData(items: any[]) {
    return items.map((elem) => {
      return {
        "Incident Number":
          elem.incidentNumber != null ? elem.incidentNumber : " ",
        "Incident Type":
          elem.incidentType != null ? elem.incidentType.name : "",
        Date: elem.date != null ? dayjs(elem.date).format("YYYY-MM-DD") : " ",
        Time: elem.time != null ? elem.time : " ",
        "ARO/SO":
          elem.armResponseOfficer.name ||
          elem.armResponseOfficer.surname != null
            ? elem.armResponseOfficer.name +
              ", " +
              elem.armResponseOfficer.surname
            : " ",
        Firearm:
          elem.firearm != null
            ? elem.firearm.serialNumber +
              "(" +
              elem.firearm.make +
              ", " +
              elem.firearm.model +
              ", " +
              elem.firearm.typeStr +
              ")"
            : " ",
        "Investigation Officer":
          elem.investigationOfficer != null ? elem.investigationOfficer : " ",
        "Officer Contact":
          elem.investigationOfficerContact != null
            ? elem.investigationOfficerContact
            : " ",
      };
    });
  }
}
