/* eslint-disable @typescript-eslint/no-explicit-any */
import { axiosInstance } from "@/plugins/axios";

const Dashboard = (): any => {
  const baseUrl = "/api/DashboardAPI";

  const GetDashboardCounts = async (): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(baseUrl + "/GetDashboardCounts");
    return Promise.resolve(res.data);
  };

  const GetFirearmList = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(baseUrl + `/GetFirearmList?id=${id}`);
    return Promise.resolve(res.data);
  };

  const GetAssignFirearmsBookedOut = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAssignFirearmsBookedOut?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const GetAssignFirearmsPastDue = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAssignFireArmsPastDue?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const GetFirearmListRenewal = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(
      baseUrl + `/GetFirearmListRenewal?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const GetShiftListOnDuty = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(
      baseUrl + `/GetShiftListOnDuty?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const GetAmmunitionListBookedOut = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAmmunitionListBookedOut?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const GetIncidentList = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(baseUrl + `/getIncidentList?id=${id}`);
    return Promise.resolve(res.data);
  };

  const GetPSIRAList = async (id: string): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(baseUrl + `/GetPSIRAList?id=${id}`);
    return Promise.resolve(res.data);
  };

  const GetPSIRAListRenewal = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(
      baseUrl + `/GetPSIRAListRenewal?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const GetSAPSList = async (id: string): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(baseUrl + `/GetSAPSList?id=${id}`);
    return Promise.resolve(res.data);
  };

  const GetSAPSListRenwal = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(
      baseUrl + `/GetSAPSListRenewal?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const GetDrivingLicense = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(
      baseUrl + `/GetDrivingLicenseList?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const GetDrivingLicenseRenewal = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(
      baseUrl + `/GetDrivingLicenseListRenewal?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const GetARO_SOCompliance = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(
      baseUrl + `/GetAROSOCompliance?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const GetMaintenanceDateFirearmList = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(
      baseUrl + `/GetMaintenanceDateFirearmList?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const GetRenewal90days = async (
    id: string
  ): Promise<Record<string, number>> => {
    const res = await axiosInstance.get(
      baseUrl + `/GetRenewals90days?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  return {
    GetDashboardCounts,
    GetFirearmList,
    GetAssignFirearmsBookedOut,
    GetAssignFirearmsPastDue,
    GetFirearmListRenewal,
    GetShiftListOnDuty,
    GetAmmunitionListBookedOut,
    GetIncidentList,
    GetPSIRAList,
    GetPSIRAListRenewal,
    GetSAPSList,
    GetSAPSListRenwal,
    GetDrivingLicense,
    GetDrivingLicenseRenewal,
    GetARO_SOCompliance,
    GetMaintenanceDateFirearmList,
    GetRenewal90days,
  };
};
const temp = Dashboard();
export default temp;
