import { axiosInstance } from "@/plugins/axios";
import { ActivityLogModel } from "models";

const ActivityLog = () => {
  const baseUrl = "/api/activityLogAPI";

  const getActivityLog = async (
    dateFrom: string,
    dateTo: string
  ): Promise<ActivityLogModel[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/getActivityLog?dateFrom=${dateFrom}&dateTo=${dateTo}`
    );
    return Promise.resolve(res.data);
  };

  const Refresh = async (
    dateFrom: string,
    dateTo: string,
    toTime: string,
    endTime: string,
    action: number,
    userId: string
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl +
        `/refreshAudit?dateFrom=${dateFrom}&dateTo=${dateTo}&toTime=${toTime}&endTime=${endTime}&action=${action}&userId=${userId}`
    );
    return Promise.resolve(res.data);
  };

  const Details = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(baseUrl + `/getDetails?id=${id}`);
    return Promise.resolve(res.data);
  };

  return {
    getActivityLog,
    Details,
    Refresh,
  };
};
const temp = ActivityLog();
export default temp;
