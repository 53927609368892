
import { Component, Vue } from "vue-property-decorator";
import { IndexedDB } from "@/hooks";
import { OfflinePermitType } from "models";
import AuthModule from "@/store/modules/Auth";
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";

@Component({
  components: {
    VuePdfApp,
  },
})
export default class OfflineFallback extends Vue {
  selected: string | null = null;
  viewPermit: OfflinePermitType | null = null;
  loading = false;
  users: string[] = [];
  openViewPermit = false;
  permits: OfflinePermitType[] = [];
  async mounted(): Promise<void> {
    try {
      this.loading = true;
      this.users = await IndexedDB.getAllUsers();
      if (this.users.length > 0) {
        this.selected = this.users[0];
      }
      await this.loadUserPermits();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  async deletePermit(item: OfflinePermitType): Promise<void> {
    if (this.selected != null) {
      await IndexedDB.deletePermit(item.id, this.selected);
      this.permits = await IndexedDB.getStoredPermits(this.selected);
    }
    return Promise.resolve();
  }
  async loadUserPermits(): Promise<void> {
    if (this.selected == null) return Promise.resolve();
    this.permits = await IndexedDB.getStoredPermits(this.selected);
    return Promise.resolve();
  }
  setViewPermit(item: OfflinePermitType): void {
    this.viewPermit = item;
    this.openViewPermit = true;
  }
}
