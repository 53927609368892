import { axiosInstance } from "@/plugins/axios";
import { Team, TeamViewModel, ArmResponseOfficer } from "models";

const Teams = () => {
  const baseUrl = "/api/manageTeams";

  const getTeams = async (): Promise<TeamViewModel[]> => {
    const res = await axiosInstance.get(baseUrl + "/getTeams");
    return Promise.resolve(res.data);
  };

  const addTeam = async (
    name: string,
    aroIDs: string,
    standByIDs: string,
    isSG: boolean
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl +
        `/addTeam?name=${name}&aroIDs=${aroIDs}&standByIDs=${standByIDs}&isSG=${isSG}`
    );
    return Promise.resolve(res.data);
  };

  const editTeam = async (
    teamID: number,
    name: string,
    aroIDs: string,
    standByIDs: string
  ): Promise<void> => {
    const res = await axiosInstance.put(
      baseUrl +
        `/updateTeam?teamID=${teamID}&name=${name}&aroIDs=${aroIDs}&standByIDs=${standByIDs}`
    );
    return Promise.resolve(res.data);
  };

  const deleteTeam = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      baseUrl + `/deleteTeam?teamID=${id}`
    );
    return Promise.resolve(res.data);
  };

  const getTeamStandByListByTeamID = async (
    id: number
  ): Promise<ArmResponseOfficer[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTeamStandByListByTeamID?teamID=${id}`
    );
    return Promise.resolve(res.data);
  };

  return {
    getTeams,
    addTeam,
    editTeam,
    deleteTeam,
    getTeamStandByListByTeamID,
  };
};
const temp = Teams();
export default temp;
