
import { Component, Vue } from "vue-property-decorator";
import update from "@/plugins/pwaUpdate";
const updater = new update();
@Component
export default class updateAvailNotif extends Vue {
  show = true;
  get updateExists(): boolean {
    return updater.getUpdateExists && this.show;
  }
  refreshApp(): void {
    updater.refreshApp();
  }
}
