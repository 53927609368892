
import { Component, Vue } from "vue-property-decorator";
import Expiry from "@/views/dashboard/tabs/ExpiryTab.vue";
import Renewal from "@/views/dashboard/tabs/RenewalTab.vue";
import Other from "@/views/dashboard/tabs/OtherTab.vue";

@Component({
  components: {
    Expiry,
    Renewal,
    Other,
  },
})
export default class Overview extends Vue {
  tab = " ";
  loading = false;
}
