/* eslint-disable @typescript-eslint/no-explicit-any */
import { axiosInstance } from "@/plugins/axios";
import {
  IncidentReport,
  AmmunitionReport,
  ARO_SOReport,
  ComplianceReport,
  FirearmReport,
  ShiftReport,
  ExceptionReport,
  PermitReport,
} from "models";

const Reports = (): any => {
  const baseUrl = "/api/ReportsAPI";

  const getIncidentReport = async (): Promise<IncidentReport[]> => {
    const res = await axiosInstance.get(baseUrl + "/getIncidentReport");
    return Promise.resolve(res.data);
  };

  const getExceptionsReport = async (): Promise<ExceptionReport[]> => {
    const res = await axiosInstance.get(baseUrl + "/getExceptionsReport");
    return Promise.resolve(res.data);
  };

  const getAmmunitionReport = async (): Promise<AmmunitionReport[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAmmunitionReport");
    return Promise.resolve(res.data);
  };

  const getShiftReport = async (): Promise<ShiftReport[]> => {
    const res = await axiosInstance.get(baseUrl + "/getShiftReportViewModel");
    return Promise.resolve(res.data);
  };

  const getFirearmReport = async (): Promise<FirearmReport[]> => {
    const res = await axiosInstance.get(baseUrl + "/getFirearmReport");
    return Promise.resolve(res.data);
  };

  const getARO_SOReport = async (): Promise<ARO_SOReport[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAROAndSGReport");
    return Promise.resolve(res.data);
  };

  const getComplianceReport = async (): Promise<ComplianceReport[]> => {
    const res = await axiosInstance.get(baseUrl + "/getComplainceReport");
    return Promise.resolve(res.data);
  };

  const getPermitReport = async (): Promise<PermitReport[]> => {
    const res = await axiosInstance.post(baseUrl + "/getAllPermits");
    return Promise.resolve(res.data);
  };

  return {
    getIncidentReport,
    getExceptionsReport, // Potentially a duplicate of Incident Reports.
    getAmmunitionReport,
    getShiftReport,
    getFirearmReport,
    getARO_SOReport,
    getComplianceReport,
    getPermitReport,
  };
};
const temp = Reports();
export default temp;
