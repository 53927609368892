import { axiosInstance } from "@/plugins/axios";
import { ArchivedARO, ArchivedFirearms } from "models";

const Archive = () => {
  const baseUrl = "/api/archivedApi";

  const getArchivedARO = async (): Promise<ArchivedARO[]> => {
    const res = await axiosInstance.get(baseUrl + "/getArchivedARO");
    return Promise.resolve(res.data);
  };

  const getArchivedFirearm = async (): Promise<ArchivedFirearms[]> => {
    const res = await axiosInstance.get(baseUrl + "/getArchivedFirearm");
    return Promise.resolve(res.data);
  };

  return {
    getArchivedARO,
    getArchivedFirearm,
  };
};
const temp = Archive();
export default temp;
