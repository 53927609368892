// declare module "prototypes" {
import { Dayjs } from "dayjs";
import * as interfaces from "models";

export class Certification implements interfaces.Certification {
  public mimeData: string;
  public mimeType: string;
  public expiration: Dayjs;
  constructor(
    params: interfaces.Certification = {} as interfaces.Certification
  ) {
    // Define the properties of the incoming `params` object here.
    // Setting a default value with the `= 0` syntax is optional for each parameter.
    const { mimeData, mimeType, expiration } = params;
    //  If needed, make the parameters publicly accessible
    //  on the class ex.: 'this.var = var'.
    /**  Use jsdoc comments here for inline ide auto-documentation */
    this.mimeData = mimeData;
    this.mimeType = mimeType;
    this.expiration = expiration;
  }
  blobA(bytes = 512): Blob {
    const byteString = atob(this.mimeData);
    const byteArrays: Uint8Array[] = [];
    for (let offset = 0; offset < byteString.length; offset += bytes) {
      const slice = byteString.slice(offset, offset + bytes);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: this.mimeType });
    return blob;
    // Other Methods:
    // atob
    //
    // return atob(this.mimeData);
    //
    // intersting asynchronous call utilizing fetch to get the memory reference, long way around the problem.
    //
    // const url = this.getValidCompetencyCertificate;
    // var data: Blob = await fetch(url).then((res) => res.blob())
    //                                  .then(console.log);
  }
  blobB() {
    // Split into two parts
    // const parts = base64Image.split(';base64,');
    // Hold the content type
    // const imageType = parts[0].split(':')[1];
    // Decode Base64 string
    // const decodedData = window.atob(parts[1]);
    const decodedData = atob(this.mimeData); // window.
    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: this.mimeType });
  }
  blob(): Blob {
    return this.blobB();
  }
  arrayBuffer() {
    // Most likely solution
    // const binaryString = atob(this.mimeData);
    // const bytes = new Uint8Array(binaryString.length);
    // for (let i = 0; i < binaryString.length; i++) {
    //   bytes[i] = binaryString.charCodeAt(i);
    // }
    // return bytes.buffer;

    return Uint8Array.from(atob(this.mimeData), (c) => c.charCodeAt(0)).buffer;

    // MimeData as a binary encoded string
    // const binaryString = atob(this.mimeData);
    // const bytes = new Uint8Array(this.mimeData.length);
    // for (let i = 0; i < this.mimeData.length; i++) {
    //   bytes[i] = this.mimeData.charCodeAt(i);
    // }
    // return bytes.buffer;
  }
  base64() {
    return "data:" + this.mimeType + ";base64," + this.mimeData;
  }
  pdf() {
    // const binaryString = atob(this.mimeData);
    // const bytes = new Uint8Array(binaryString.length);
    // for (let i = 0; i < binaryString.length; i++) {
    //   bytes[i] = binaryString.charCodeAt(i);
    // }
    // return bytes.buffer;
    // const enc = new TextEncoder(); // always utf-8
    // return enc.encode(this.mimeData).buffer;
    // window.open(
    //   "data:" + this.mimeType + ";base64," + encodeURI(this.mimeData),
    //   "_blank"
    // );
    // function downloadPDF(pdf) {
    //  const linkSource = `data:application/pdf;base64,${pdf}`;
    //  const downloadLink = document.createElement("a");
    //  const fileName = "abc.pdf";
    //  downloadLink.href = linkSource;
    //  downloadLink.download = fileName;
    //  downloadLink.click();}
    const blob = this.blobB();
    const url = URL.createObjectURL(blob);
    window.open(url);
  }
}
// }
