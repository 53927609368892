
import { Component, Vue } from "vue-property-decorator";
import { Downloader, Reports } from "@/hooks";
import { FirearmReport } from "models";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import JsonExcel from "@/components/JsonExcel.vue";
import dayjs from "dayjs";

@Component({
  components: {
    JsonExcel,
  },
  filters: {
    daysRemaining: function (value: string) {
      const today = dayjs();
      const exp = dayjs(value);
      const rem = exp.diff(today, "d");

      return rem;
    },
  },
})
export default class Incident_Report extends Vue {
  reports: FirearmReport[] = [];
  reportsFiltered: FirearmReport[] = [];
  loading = false;
  search = "";
  sortDesc = true;
  sortBy = "expiryDateStr";
  expanded: FirearmReport[] = [];
  headers = [
    { text: "Make", value: "make" },
    { text: "Model", value: "model" },
    { text: "Type", value: "typeStr" },
    { text: "S/N", value: "serialNumber" },
    { text: "Expiry Date", value: "expiryDateStr" },
    { text: "Days to Expiry", value: "daysToExpiry" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    Make: string;
    Model: string;
    Type: string;
    "S/N": string;
    "Expiry Date": string;
    "Days to Expiry": string;
    Calibre: string;
    "License No": string;
    "Date of Issue": string;
  }[] = [];
  expFields = {
    Make: "make",
    Model: "model",
    Type: "typeStr",
    "S/N": "serialNumber",
    "Expiry Date": "expiryDateStr",
    "Days to Expiry": "daysToExpiryCalc",
    Calibre: "calibre",
    "License No": "licenseNumber",
    "Date of Issue": "fromDateStr",
  };

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const res = await Reports.getFirearmReport();
      this.reports = res;
      this.expReports = this.populateExportData(this.reports);
      this.loading = false;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  updateReportsFiltered(event: any) {
    this.reportsFiltered = event ? (event as any[]) : this.reports;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.reportsFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      const columns = [
        { header: "Make", dataKey: "Make" },
        { header: "Model", dataKey: "Model" },
        { header: "Type", dataKey: "Type" },
        { header: "S/N", dataKey: "S/N" },
        { header: "Expiry Date", dataKey: "Expiry Date" }, // { header: "Expiry Date", dataKey: "expiry-date" }
        { header: "Days to Expiry", dataKey: "Days to Expiry" },
        { header: "Calibre", dataKey: "Calibre" },
        { header: "License No", dataKey: "License No" },
        { header: "Date of Issue", dataKey: "Date of Issue" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `Firearm Report (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  populateExportData(items: any[]) {
    return items.map((elem) => {
      return {
        Make: elem.make != null ? elem.make : " ",
        Model: elem.model != null ? elem.model : " ",
        Type: elem.typeStr != null ? elem.typeStr : " ",
        "S/N": elem.serialNumber != null ? elem.serialNumber : " ",
        "Expiry Date": elem.expiryDateStr != null ? elem.expiryDateStr : " ",
        "Days to Expiry":
          elem.expiryDate != null ? this.getDaysToExpiry(elem.expiryDate) : " ",
        Calibre: elem.calibre != null ? elem.calibre : " ",
        "License No": elem.licenseNumber != null ? elem.licenseNumber : " ",
        "Date of Issue": elem.fromDateStr != null ? elem.fromDateStr : " ",
      };
    });
  }

  getDaysToExpiry(i: any): string {
    const today = dayjs();
    const exp = dayjs(i);
    const rem = exp.diff(today, "d");
    return String(rem);
  }

  getColor(i: any): string {
    const today = dayjs();
    const exp = dayjs(i);
    const rem = exp.diff(today, "d");

    if (rem >= 211) return "green";
    else if (rem >= 181 && rem <= 210) return "yellow";
    else if (rem >= 151 && rem <= 180) return "orange";
    else if (rem >= 0 && rem <= 150) return "red";
    else return "red darken-4";
  }
}
