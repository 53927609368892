import { axiosInstance } from "@/plugins/axios";
import { ShiftSite } from "models";

const ShiftSite = () => {
  const baseUrl = "/api/shiftSite";

  const getShiftSiteByID = async (id: number): Promise<ShiftSite> => {
    const res = await axiosInstance.get(
      baseUrl + "/getShiftSiteByID?shiftSiteID=" + id
    );
    return Promise.resolve(res.data[0]);
  };
  const getShiftSites = async (): Promise<ShiftSite[]> => {
    const res = await axiosInstance.get(baseUrl + "/getShiftSites");
    return Promise.resolve(res.data);
  };

  const getTeamAndSiteList = async (isSG: boolean): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTeamAndSiteList?isSG=${isSG}`
    );
    return Promise.resolve(res.data);
  };

  return {
    getShiftSiteByID,
    getShiftSites,
    getTeamAndSiteList,
  };
};
const temp = ShiftSite();
export default temp;
