import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import { UserDetails, userRole } from "models";
import store from "@/store";
import { subscription } from "subscriptionModels";

@Module({
  dynamic: true,
  namespaced: true,
  name: "Subscription",
  store,
})
class Subscription extends VuexModule {
  currentSubscription: subscription | null = null;
  packages: subscription[] = [];

  get getPackages() {
    return this.packages;
  }
  @Mutation
  setPackages(val: subscription[]) {
    this.packages = val;
  }

  get getSubscription() {
    return this.currentSubscription;
  }
  @Mutation
  setSubscription(val: subscription | null) {
    this.currentSubscription = val;
  }
}
export default getModule(Subscription);
