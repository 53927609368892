import { axiosInstance } from "@/plugins/axios";
import { Ammunition, Firearm } from "models";

const Ammo = () => {
  const baseUrl = "/api/ammunitionAPI";

  const getFirearmAmmunition = async (id: number): Promise<Ammunition> => {
    const res = await axiosInstance.get(
      baseUrl + "/getFirearmAmmunition?firearmID=" + id
    );
    return Promise.resolve(res.data[0]);
  };

  const getAmmunition = async (): Promise<Ammunition[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAmmunition");
    return Promise.resolve(res.data);
  };

  const addAmmunition = async (formData: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + `/addAmmunition`, formData);
    return Promise.resolve(res.data);
  };

  const GetAmmunitionListByCalibre = async (calibre: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAmmunitionListByCalibre?calibre=${calibre}`
    );
    return Promise.resolve(res.data);
  };

  const GetAmmunitionFiles = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAmmunitionFiles?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const DownLoadAmmunitionFile = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/downLoadAmmunitionFile?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getFirearmAmmunition,
    getAmmunition,
    addAmmunition,
    GetAmmunitionListByCalibre,
    GetAmmunitionFiles,
    DownLoadAmmunitionFile,
  };
};
const temp = Ammo();
export default temp;
